import React from 'react'
import './CompanyAboutVideo.css'
import useAnimation from '../hooks/useAnimation'

function VideoPlayer() {
  useAnimation()
  return (
    <div className="about-video" data-aos="zoom-in" data-aos-duration="700">
      <iframe
        width="100%"
        height="100%"
        frameBorder="0"
        src="https://www.youtube.com/embed/sONGc22TCQ8"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default VideoPlayer
