import React, { useState, useRef } from 'react'
import Header from '../Header'
import '../../globals.css'
import './ContactPage.css'
import { THEME_DARK } from '../../consts'
import telIcon from '../../images/icon/contact-icon/contact-tel-icon.svg'
import mailIcon from '../../images/icon/contact-icon/contact-mail-icon.svg'
import locationIcon from '../../images/icon/contact-icon/contact-location-icon.svg'
import facebookIcon from '../../images/icon/social-network-icon/logo-facebook-contact.svg'
import instagramIcon from '../../images/icon/social-network-icon/logo-instagram-contact.svg'
import linkedinIcon from '../../images/icon/social-network-icon/logo-linkedin-contact.svg'
import emailjs from '@emailjs/browser'
import Alert from '@mui/material/Alert'
import { useTranslation } from 'react-i18next'

const ContactPage = () => {
  const [t] = useTranslation('global')

  const contactInfo = [
    {
      type: 'tel',
      content: '+995 558 18 75 75',
      icon: telIcon,
      link: 'tel:+995558187575',
    },
    {
      type: 'mailto',
      content: 'info@wevosoft.com',
      icon: mailIcon,
      link: 'mailto:info@wevosoft.com',
    },
    {
      type: 'location',
      content: t('footer-section.footer-location'),
      icon: locationIcon,
    },
  ]

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: '',
  })
  const form = useRef()
  const handleNameChange = (e) => setName(e.target.value)
  const handleEmailChange = (e) => setEmail(e.target.value)
  const handleMessageChange = (e) => setMessage(e.target.value)
  const [alertVisible, setAlertVisible] = useState(false)

  const validateForm = () => {
    const newErrors = {
      name: '',
      email: '',
      message: '',
    }

    if (name.trim().length < 2) {
      newErrors.name = t(
        'contact-page.contact-page-form-error-message.minTwoSymb'
      )
    }
    if (!email.trim() || !/^[^@]+@[^@]+$/.test(email)) {
      newErrors.email = t(
        'contact-page.contact-page-form-error-message.emailSymb'
      )
    }
    if (message.trim().length < 3) {
      newErrors.message = t(
        'contact-page.contact-page-form-error-message.minThreeSymb'
      )
    }

    setErrors(newErrors)

    return !Object.values(newErrors).some((error) => error)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (validateForm()) {
      emailjs
        .sendForm('service_ubjpaay', 'template_ubuw38c', form.current, {
          publicKey: 'hqYhN4q7MlMremgFT',
        })
        .then(
          () => {
            // console.log('SUCCESS!')
            setName('')
            setEmail('')
            setMessage('')
            setErrors({ name: '', email: '', message: '' })
            setAlertVisible(true)
            setTimeout(() => {
              setAlertVisible(false)
            }, 5000)
          },
          (error) => {
            console.log('FAILED...', error.text)
          }
        )
    }
  }

  return (
    <section>
      <Header theme={THEME_DARK} />
      <div className="contact-hero">
        <h2 className="contact-hero-title">
          <div className="contact-hero-title-in">
            {t('contact-page.contact-page-title')}
          </div>
        </h2>
      </div>
      <div className="contact-container sectionContainer">
        <div className="contact-form">
          <h2>{t('contact-page.contact-page-form-title')}</h2>
          <form ref={form} noValidate onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="form-name">
                {t('contact-page.contact-page-form-label.item1')}
              </label>
              <input
                placeholder={t(
                  'contact-page.contact-page-form-placeholder.item1'
                )}
                type="text"
                value={name}
                onChange={handleNameChange}
                name="user_name"
                id="form-name"
              />
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="form-email">
                {t('contact-page.contact-page-form-label.item2')}
              </label>
              <input
                placeholder={t(
                  'contact-page.contact-page-form-placeholder.item2'
                )}
                type="email"
                value={email}
                onChange={handleEmailChange}
                name="user_email"
                id="form-email"
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="form-message">
                {t('contact-page.contact-page-form-label.item3')}
              </label>
              <textarea
                placeholder={t(
                  'contact-page.contact-page-form-placeholder.item3'
                )}
                value={message}
                onChange={handleMessageChange}
                name="message"
                id="form-message"
              ></textarea>
              {errors.message && (
                <p className="error-message">{errors.message}</p>
              )}
            </div>
            <div className="form-submit-container">
              <button type="submit">
                {t('contact-page.contact-page-form-btn')}
              </button>
            </div>
            {alertVisible ? (
              <Alert
                style={{
                  fontSize: '12px',
                  width: '50%',
                  margin: '10px auto 0 auto',
                }}
                severity="success"
              >
                {t('contact-page.contact-page-form-btn-alert')}
              </Alert>
            ) : (
              ''
            )}
          </form>
        </div>
        <div className="contact-info">
          <h2>{t('contact-page.contact-page-addresses-title')}</h2>
          <address className="contact-info-addresses">
            <ul className="contact-info-list">
              {contactInfo.map((info, index) => (
                <li className="contact-info-list-item" key={index}>
                  <img
                    className="contact-info-list-item-img"
                    src={info.icon}
                    alt={`${info.type} icon`}
                  />
                  {info.type === 'tel' && (
                    <a className="contact-info-list-link" href={info.link}>
                      {info.content}
                    </a>
                  )}
                  {info.type === 'mailto' && (
                    <a className="contact-info-list-link" href={info.link}>
                      {info.content}
                    </a>
                  )}
                  {info.type === 'location' && (
                    <span className="contact-info-list-link">
                      {info.content}
                    </span>
                  )}
                </li>
              ))}
            </ul>
            <div className="contact-info-social-networks-container">
              <a
                rel="noreferrer"
                href="https://www.facebook.com/people/Wevosoft/61562706014061/"
                target="_blank"
                className="contact-info-social-network-link"
              >
                <img
                  className="contact-info-social-network-icon"
                  src={facebookIcon}
                  alt="facebook"
                />
              </a>
              <a
                rel="noreferrer"
                href="https://www.instagram.com/wevosoft_international?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                target="_blank"
                className="contact-info-social-network-link"
              >
                <img
                  className="contact-info-social-network-icon"
                  src={instagramIcon}
                  alt="instagram"
                />
              </a>
              <a
                rel="noreferrer"
                href="https://www.linkedin.com/company/wevosoft/"
                target="_blank"
                className="contact-info-social-network-link"
              >
                <img
                  className="contact-info-social-network-icon"
                  src={linkedinIcon}
                  alt="linkedin"
                />
              </a>
            </div>
          </address>
        </div>
      </div>
    </section>
  )
}

export default ContactPage
